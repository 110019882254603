import React from "react"
import styled from 'styled-components'
import { graphql, Link } from 'gatsby'
import { PageTitle, Picture, TransitionMask } from '@components'
import { Grid, GridItem, } from '@components/grid'
import { PageProps } from '@types'
import { H4 } from '@components/BlockRenderer'


const ResidenciesPage = ({data: { 
  sanityPage: {
    gridItems,
    title,
  },
  allSanityResidency
}}: PageProps) => {
  const { nodes } = allSanityResidency!
  return (
    <>
      <Grid>
        {gridItems.filter(p => !!p).map((item) => (
          <GridItem key={item._key} {...item} />
        ))}
        {nodes.map((r => (
          <Residency key={r._key} cardColor={r.cardColor.hex} >
            <StyledLink to={`/${r.slug.current}`}>
              <Picture 
                src={r.shareImage?.asset.url!} 
                dimensions={r.shareImage?.asset.metadata.dimensions!}
              />
              <H4 className="switchable" >{r.title}</H4>
            </StyledLink>
          </Residency>
        )))}
        <StyledResidency cardColor="#b2824a" >
          <H4><Link to="/new-residencies" >Contact us for new residencies in your area and investment opportunities</Link></H4>
        </StyledResidency>
      </Grid>
      
      <TransitionMask />
    </>
  )
}

const Residency = styled.div<{ cardColor: string }>`
  grid-column-end: span 4;
  height: 100%;
  min-height: 240px;
  padding: 10px;
  background-color: ${props => props.cardColor};
  color: black;
  @media only screen and (min-width: 768px) {
    grid-column-end: span 2;
  }
  ${H4} {
    padding-top: 0.5em;
  }
`

const StyledLink = styled(props => <Link {...props} />)`
  text-decoration: none;
`

const StyledResidency = styled(props => <Residency {...props} />)`
  padding: 20px;
  ${H4} {
    padding-top: 0;
    a {
      text-decoration: none;
    }
  }
`

const ContactUs = styled.div`
  background-color: #b2824a;

`


export const query = graphql`
  query ResidenciesPageQuery {
    sanityPage(slug: {current: {eq: "residencies"}}) {
      ...pageFields
    }
    allSanityResidency {
      nodes {
        _key
        cardColor {
          hex
        }
        title
        titlePt
        titleRu
        slug {
          current
        }
        shareImage {
          asset {
            url
            metadata {
              dimensions {
                width
                height
                aspectRatio
              }
            }
          }
        }
      }
    }
  }
`

export default ResidenciesPage
